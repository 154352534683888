/* scrollbar */
@import 'simplebar-react/dist/simplebar.min.css';
.css-14lo706{
    width: auto;
    overflow: hidden;
    display: block;
    padding: 0;
    height: 11px;
    font-size: 4rem;
    visibility: hidden;
    max-width: 100%;
    -webkit-transition: max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms;
    transition: max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms;
    white-space: nowrap;
}